const calculatePrice = () => {
  let currencyMask = document.getElementById("mask-target").inputmask;
  let quantity = document.getElementById("batch_quantity").value;
  let avgWeight = document.getElementById("batch_average_weight").value;
  let kiloPrice = document.getElementById("batch_kilo_price").value;
  let totalPrice = currencyMask.format(quantity * avgWeight * kiloPrice);
  document.getElementById("batchPrice").innerText =
    "Precio de la tropa: " + totalPrice;
};

function changeRinde(event) {
  let rinde_field = document.getElementById("batch_rinde");
  if (this.value === "feedlot") {
    rinde_field.value = 58;
  } else if (this.value === "racionada") {
    rinde_field.value = 57;
  } else if (this.value === "campo") {
    rinde_field.value = 56;
  }
}

const detectBatchForm = () => {
  let kilo_price_input = document.getElementById("batch_kilo_price");
  let quantity_input = document.getElementById("batch_quantity");
  let average_weight_input = document.getElementById("batch_average_weight");
  let fatting_radios = document.querySelectorAll(
    'input[type=radio][name="batch[fatting_type]"]'
  );

  if (kilo_price_input && fatting_radios) {
    Array.prototype.forEach.call(fatting_radios, function (radio) {
      radio.addEventListener("change", changeRinde);
    });
    kilo_price_input.addEventListener("keyup", calculatePrice);
    kilo_price_input.addEventListener("click", calculatePrice);
    quantity_input.addEventListener("keyup", calculatePrice);
    quantity_input.addEventListener("click", calculatePrice);
    average_weight_input.addEventListener("keyup", calculatePrice);
    average_weight_input.addEventListener("click", calculatePrice);
  }
};

export { detectBatchForm };
