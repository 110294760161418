const checkContacted = () => {

  const input = document.getElementById('checkContacted')
  if (input) {

    const link = document.getElementById('continue')

    input.addEventListener('change', function() {
      if(this.checked) {
        link.classList.remove('disabled')
      } else {
        link.classList.add('disabled')
      }
    })
  }

}

export { checkContacted }