const toggleRoleCard = () => {
  const form = document.querySelector('.new_user_role')

  if (form) {
    const cardProductor = document.getElementById('productor')
    const cardMatarife = document.getElementById('matarife')
    const cardMatricula = document.getElementById('matricula-container')
    const inputMatricula = document.getElementById('user_role_user_attributes_matricula')

    const idRadioProductor = cardProductor.attributes.for.value
    const idRadioMatarife = cardMatarife.attributes.for.value

    const submitButton = document.getElementById('submit')

    document.addEventListener('click', (e) => {
      if (e.target.id == idRadioProductor){
        cardMatarife.classList.remove('selected')
        cardProductor.classList.add('selected')
        cardMatricula.classList.add('invisible')
        inputMatricula.value = ''
        submitButton.disabled = false
      } else if (e.target.id == idRadioMatarife){
        cardProductor.classList.remove('selected')
        cardMatarife.classList.add('selected')
        cardMatricula.classList.remove('invisible')
        submitButton.disabled = true
        inputMatricula.addEventListener("input", function(){
          submitButton.disabled = (this.value === '');
        });
      }
    })
  }
}

export { toggleRoleCard }
