import { Controller } from "stimulus"
import { DirectUpload } from "@rails/activestorage"
import Dropzone from "dropzone"
import { getMetaValue, toArray, findElement, removeElement, insertAfter } from "./helpers"

Dropzone.autoDiscover = false
Dropzone.prototype.defaultOptions.dictFileTooBig = "El archivo es demasiado grande ({{filesize}}MiB). El maximo es: {{maxFilesize}} MiB.";
Dropzone.prototype.defaultOptions.dictCancelUploadConfirmation = "¿Esta seguro de cancelar la carga del video?";
Dropzone.prototype.defaultOptions.dictMaxFilesExceeded = "No puede subir mas archivos";
Dropzone.prototype.defaultOptions.dictRemoveFile = "Remover archivo";
Dropzone.prototype.defaultOptions.dictCancelUpload = "Cancelar carga";
Dropzone.prototype.defaultOptions.dictInvalidFileType = "Formato de archivo incorrecto";

export default class extends Controller {
  static targets = [ "input", "zone", "submit", "videoLoadingMessage" ]

  connect() {
    this.dropZone = createDropZone(this)
    this.hideFileInput()
    this.bindEvents()
  }

// Private
  hideFileInput() {
    this.inputTarget.disabled = true
    this.inputTarget.style.display = "none"
  }

  bindEvents() {
    this.dropZone.on("addedfile", (file) => {
      setTimeout(() => {
        file.accepted && createDirectUploadController(this, file).start()
       }, 500)
    })

    this.dropZone.on("success", (file) => {
    })

    this.dropZone.on("removedfile", (file) => {
      file.controller && removeElement(file.controller.hiddenInput)
    })

    this.dropZone.on("canceled", (file) => {
      file.controller && file.controller.xhr.abort()
    })

    this.dropZone.on("processing", (file) => {
      this.submitTarget.disabled = true;
      this.videoLoadingMessageTarget.classList.remove("d-none");
    })

    this.dropZone.on("queuecomplete", (file) => {
      this.submitTarget.disabled = false;
      this.videoLoadingMessageTarget.classList.add("d-none");
      this.submitTarget.classList.remove("d-none");
    })
  }

  get headers() { return { "X-CSRF-Token": getMetaValue("csrf-token") } }

  get url() { return this.inputTarget.getAttribute("data-direct-upload-url") }

  get maxFiles() { return this.data.get("maxFiles") || 1 }

  get maxFileSize() { return this.data.get("maxFileSize") || 256 }

  // get acceptedFiles() { return this.data.get("acceptedFiles") }
  get acceptedFiles() { return "video/*" }

  get form() { return this.element.closest("form") }

  get zone() { return this.zoneTarget }

  get submitButton() { return this.submitTarget }

}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this)
    this.source = source
    this.file = file
  }

  start() {
    this.file.controller = this
    this.hiddenInput = this.createHiddenInput()
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput)
        this.emitDropzoneError(error)
      } else {
        this.hiddenInput.value = attributes.id
        this.emitDropzoneSuccess()
      }
    })
  }

// Private
  createHiddenInput() {
    const input = document.createElement("input")
    input.type = "hidden"
    input.name = this.source.inputTarget.name + "[]"
    insertAfter(input, this.source.inputTarget)
    return input
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr)
    this.emitDropzoneUploading()
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr
    this.xhr.upload.addEventListener("progress", event => {
      this.uploadRequestDidProgress(event)
    })
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element
    const progress = (event.loaded / event.total) * 100
    findElement(this.file.previewTemplate, ".dz-upload").style.width = `${progress}%`
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING
    this.source.dropZone.emit("processing", this.file)
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR
    this.source.dropZone.emit("error", this.file, error)
    this.source.dropZone.emit("complete", this.file)
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS
    this.source.dropZone.emit("success", this.file)
    this.source.dropZone.emit("complete", this.file)
  }
}

// Top level...
function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file)
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller)
}

function createDropZone(controller) {
  return new Dropzone(controller.zone, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: true,
    autoQueue: false,
  })
}
