const openModal = () => {
  const btn = document.getElementById("register-button");

  if (btn) {
    btn.addEventListener("click", function() {
      $('#loadingRegistration').modal('show');
    });
  }

}

export { openModal }
