const showFileName = (originalLabel) => {
  const fileInput = document.getElementById(event.target.id);
  if (fileInput) {
    const label = fileInput.labels[0];
    label.innerHTML =
      originalLabel + "<strong> (" + fileInput.files[0].name + ")</strong>";
  }
  document.querySelector("input[type=submit]").disabled = false;
};

const fileForms = () => {
  const inputFields = Array.from(
    document.getElementsByClassName("pdf-file-input")
  );
  if (inputFields.length > 0) {
    inputFields.forEach((item) =>
      item.addEventListener(
        "change",
        showFileName.bind(null, item.labels[0].innerHTML)
      )
    );
    document.querySelector("input[type=submit]").disabled = true;
  }
};

export { fileForms };
