// Implement Slideout.js?

const toggleNavbar = () => {
  const navbar = document.getElementById("sidebar");
  if (navbar) {
    const button = document.getElementById("toggle-sidebar");

    document.querySelector("main").addEventListener("click", (e) => {
      if (navbar.classList.contains("open") && !navbar.contains(e.target)){
        navbar.classList.remove("open");
      }
    });

    button.addEventListener("click", (e) => {
      e.preventDefault();
      navbar.classList.toggle("open");
    })
  }
}

const toggleAnimatedBtn = () => {
  const menu = document.getElementById("animated-hamburger");
  if (menu) {
    const btn = document.getElementById("toggle-sidebar");

    btn.addEventListener("click", (e) => {
      e.preventDefault();
      menu.classList.toggle("open");
    })
  }
}

export { toggleNavbar, toggleAnimatedBtn };
