const loginAnimation = () => {
  const form = document.getElementById('new_user_form');
  if (form) {
    setTimeout(() => {
      form.style.height = "230px";
      form.style.opacity = "1";
    }, 1000);
  }
}

const showPassword = () => {
  var x = document.getElementById("user_password");
  if (x.type === "password") {
    x.type = "text";
  } else {
    x.type = "password";
  }
}

const showButton = document.getElementById('show-password');

if (showButton) {
  showButton.addEventListener('click', (e) => {
    showPassword()
  })
}

export { loginAnimation }