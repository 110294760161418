import Inputmask from "inputmask";

Inputmask.extendDefaults({
    'removeMaskOnSubmit': true,
    'showMaskOnHover': false
});

Inputmask.extendAliases({
    'argCurrency': {
        alias: "currency",
        prefix: "$ ",
        groupSeparator: ".",
        digits: false,
        min: 1,
        rightAlign: false,
        placeholder: '',
        allowPlus: false,
        allowMinus: false,
    }
});

Inputmask.extendAliases({
    'fattingTime': {
        alias: "numeric",
        groupSeparator: ".",
        digits: false,
        min: 1,
        rightAlign: false,
        placeholder: '',
        allowPlus: false,
        allowMinus: false,
        suffix: " días"
    }
});

Inputmask.extendAliases({
    'averageWeight': {
        alias: "fattingTime",
        suffix: " kg"
    }
});

const initInputMask = () => {
    let cuit_field = document.getElementById("signup-cuit");
    if (cuit_field) {
        Inputmask({"mask": "99-99999999-9"}).mask(cuit_field);
    }

    // let fatting_time_field = document.getElementById("batch_fatting_time");
    // let kilo_price_field = document.getElementById("batch_kilo_price");
    // let avg_weight_field = document.getElementById("batch_average_weight");
    let maskTargetField = document.getElementById("mask-target");
    if (maskTargetField) {
        Inputmask({"alias": "argCurrency"}).mask(document.getElementById("mask-target"));
        // Inputmask({"alias": "fattingTime"}).mask(fatting_time_field);
        // Inputmask({"alias": "argCurrency"}).mask(kilo_price_field);
        // Inputmask({"alias": "averageWeight"}).mask(avg_weight_field);
    }
}


export {initInputMask};