const setClipboard = (value) => {
  var tempInput = document.createElement("textarea");
  tempInput.style = "position: absolute; left: -1000px; top: -1000px";
  tempInput.value = value;
  document.body.appendChild(tempInput);
  tempInput.select();
  document.execCommand("copy");
  document.body.removeChild(tempInput);
}

const insertAfter = (el, referenceNode) => {
  referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
}

const copyText = () => {

  const copyButton = document.getElementById("copy-bank-details");

  if (copyButton) {
    copyButton.addEventListener('click', (e) => {
      e.preventDefault();
      let cuit = document.getElementById("operation-cuit").innerText;
      let alias = document.getElementById("operation-alias").innerText
      let cbu = document.getElementById("operation-cbu").innerText

      setClipboard(`CUIT: ${cuit}\nAlias: ${alias}\nCBU: ${cbu}`);
  
      const infoText = document.createElement('div');
      infoText.innerHTML = '<p class="small my-1 text-center">Datos copiados!</p>';
      insertAfter(infoText, copyButton);
    });
  }
}

export { copyText };
