const notificationInstall = document.getElementById("install-pwa");

if (notificationInstall) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js').then(registration => {
      console.log('ServiceWorker registered: ', registration);
  
      var serviceWorker;
      if (registration.installing) {
        serviceWorker = registration.installing;
        console.log('Service worker installing.');
      } else if (registration.waiting) {
        serviceWorker = registration.waiting;
        console.log('Service worker installed & waiting.');
      } else if (registration.active) {
        serviceWorker = registration.active;
        console.log('Service worker active.');
      }
    }).catch(registrationError => {
      console.log('Service worker registration failed: ', registrationError);
    });
  });
  
  let deferredPrompt;
  
  window.addEventListener('beforeinstallprompt', (e) => {
    // Prevent the mini-infobar from appearing on mobile
    e.preventDefault();
    // Stash the event so it can be triggered later.
    deferredPrompt = e;
    // Update UI notify the user they can install the PWA
    showInstallPromotion();
  });
  
  
  const buttonInstall = document.getElementById("install-button");
  const showInstallPromotion = () => {
    notificationInstall.classList.add("d-fixed")
  }
  
  const hideMyInstallPromotion = () => {
    notificationInstall.classList.add("d-none")  
  }
  
  buttonInstall.addEventListener('click', (e) => {
    // Hide the app provided install promotion
    // hideMyInstallPromotion();
    // Show the install prompt
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }
    });
  });
  
  window.addEventListener('appinstalled', (evt) => {
    // Log install to analytics
    console.log('INSTALL: Success');
    hideMyInstallPromotion();
  });
  
  window.addEventListener('DOMContentLoaded', () => {
  
    const iOS = ( navigator.userAgent.match(/(iPad|iPhone|iPod)/i) ? true : false );
  
    let displayMode = 'browser tab';
    if (navigator.standalone) {
      displayMode = 'standalone-ios';
    }
    if (window.matchMedia('(display-mode: standalone)').matches) {
      displayMode = 'standalone';
    }
  
    if (displayMode !== 'browser tab' || iOS) {
      hideMyInstallPromotion();
    }
  });   
}