function toggleMonotributista(event) {
  let monotributista_field = document.querySelector(
    "div .custom-control.custom-switch"
  );
  if (this.value === "1") {
    monotributista_field.classList.add("d-none");
  } else if (this.value === "0") {
    monotributista_field.classList.remove("d-none");
  }
}

const toggleBankAccount = () => {
  const form = document.querySelector(".new_bank_account");

  if (form) {
    const cbu = document.getElementById("cbu");
    const submit = document.getElementById("submit");
    const populateInputs = (e) => {
      if (e.target.value.length > 2) {
        const match = e.target.value.toString().match(/(?<bank>\d{3})/);
        document.getElementById("bank_entity_id").value = match.groups.bank;
      }

      cbu.value.length == 22
        ? (submit.disabled = false)
        : (submit.disabled = true);
    };

    cbu.addEventListener("change", populateInputs, false);
    cbu.addEventListener("click", populateInputs, false);
    cbu.addEventListener("keypress", populateInputs, false);

    let person_type = document.querySelectorAll(
      'input[type=radio][name="bank_account[legal_person]"]'
    );
    Array.prototype.forEach.call(person_type, function (radio) {
      radio.addEventListener("change", toggleMonotributista);
    });
  }
};

export { toggleBankAccount };
