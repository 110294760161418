const calculateBatchPrice = e => {
    let currencyMask = document.getElementById("mask-target").inputmask
    let kiloPrice = e.target.value
    let weight = +e.target.closest("div.new-price").querySelector("small.weight").innerText
    let quantity = +e.target.closest("div.new-price").querySelector("small.quantity").innerText

    let newBatchTotalPrice = e.target.closest("div.new-price").querySelector("small.new-batch-total-price")
    newBatchTotalPrice.innerText = kiloPrice * weight * quantity

    let totalPriceFields = document.querySelectorAll("small.new-batch-total-price");
    let totalPrice = 0;
    for (let i = 0; i < totalPriceFields.length; i++) {
        totalPrice += +totalPriceFields[i].innerText;
    }
    let monotributista = document.getElementById("monotributista") === null;
    const userCommission = parseFloat(document.getElementById("user-commission").innerText);
    let haciendaPrice = totalPrice;
    let commissionAmount = totalPrice * userCommission;
    let ivaAmount = 0;
    if (!monotributista) {
      ivaAmount = totalPrice * 0.105;
      document.getElementById("iva-price").innerText = currencyMask.format(ivaAmount);
    }
    totalPrice = haciendaPrice + ivaAmount + commissionAmount

    document.getElementById("hacienda-price").innerText = currencyMask.format(haciendaPrice);
    document.getElementById("commission-amount").innerText = currencyMask.format(commissionAmount);
    document.getElementById("counteroffer-new-price").innerText = currencyMask.format(totalPrice);
}


const calculateCounterofferPrice = () => {
    let currencyMask = document.getElementById("mask-target");
    let new_kilo_price_fields = document.querySelectorAll('input.counteroffer-new-kiloprice');
    if (currencyMask && new_kilo_price_fields) {
        Array.prototype.forEach.call(new_kilo_price_fields, function (field) {
            field.addEventListener('input', calculateBatchPrice)
        });
    }
}

export {calculateCounterofferPrice}
