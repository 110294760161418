import places from 'places.js';

const initAutocomplete = () => {
  const addressInput = document.getElementById('user_address_street');
  if (addressInput) {
    const fixedOptions = {
      // appId: 'YOUR_PLACES_APP_ID',
      // apiKey: 'YOUR_PLACES_API_KEY',
      container: addressInput,
    };

    const reconfigurableOptions = {
      language: 'es',
      countries: ['ar'],
      type: 'address'
    };

    places(fixedOptions).configure(reconfigurableOptions);
  }
};

export { initAutocomplete };
