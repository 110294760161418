import "bootstrap-star-rating";
import "bootstrap-star-rating/css/star-rating.min.css"

const initStarRating = () => {
  $("#review_rating").rating({
    min:0, 
    max:5, 
    step:1, 
    size:'md',
    filledStar: '<i class="fas fa-star"></i>',
    emptyStar: '<i class="far fa-star"></i>',
    clearButton: '<i class="fa fa-lg fa-minus-circle"></i>',
    showClear: false, 
    showCaption: false,
    animate: false,
    containerClass: "text-primary"
  });
};

export { initStarRating };
