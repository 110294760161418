const updateCharsCounter = () => {
    const textArea = document.getElementById("counter-text-area");
    const counter = document.getElementById("chars-counter");

    counter.innerText = `${textArea.value.length}/${textArea.maxLength}`
}


const charsCounter = () => {
    const textArea = document.getElementById("counter-text-area");
    const counter = document.getElementById("chars-counter");

    if (textArea && counter) {
        textArea.addEventListener('input', updateCharsCounter);
    }
}

export {charsCounter}