const disableSubmitBtn = () => {
  let form = document.querySelector("form");
  if (form && form.method !== "get") {
    form.addEventListener("submit",
      () => {
        form.querySelector("[type=submit]").disabled = true
      }
    )
  }
}

export {disableSubmitBtn}
